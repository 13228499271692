import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import RouteConfig from "./configs/RouteConfig";
import { validateMessages } from "./configs/config";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import "moment/locale/zh-cn";

ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
        <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} form={{ validateMessages }}>
            <RouteConfig />
        </ConfigProvider>
    </DndProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
