import React, { Suspense, lazy } from "react";
import { Spin } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const NotAuthed = lazy(() => import("../pages/account/NotAuthed"));
const NotFound = lazy(() => import("../pages/account/NotFound"));
const SignIn = lazy(() => import("../pages/account/SignIn"));

const ConsoleLayout = lazy(() => import("../layouts/basic/ConsoleLayout"));

const RouteConfig = () => (
    <Router basename={process.env.REACT_APP_BASE_NAME}>
        <Suspense fallback={<Spin />}>
            <Switch>
                <Route path="/account/signIn" component={SignIn} />
                <Route path="/error/404" component={NotFound} />
                <Route path="/error/403" component={NotAuthed} />

                <Route path="/">
                    <ConsoleLayout />
                </Route>
                <Route path="*" component={NotFound} />
            </Switch>
        </Suspense>
    </Router>
);

export default RouteConfig;
